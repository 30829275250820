import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const NewZealand = () => {
    return (
        <Layout>
            <Seo title="New Zealand" />

            <h1 className="title">New Zealand</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr className="thead title">
                        <td colSpan="2">IP Searching</td>
                    </tr>

                    <tr>
                        <td>IPONZ IP searches</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.iponz.govt.nz/manage-ip/">https://www.iponz.govt.nz/manage-ip/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Guidance and legislation</td>
                    </tr>

                    <tr>
                        <td>Patent Examination Manual</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.iponz.govt.nz/about-ip/patents/examination-manual/">https://www.iponz.govt.nz/about-ip/patents/examination-manual/</a></td>
                    </tr>

                    <tr>
                        <td>Patents Act 2013</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.govt.nz/act/public/2013/0068/latest/DLM1419043.html">https://www.legislation.govt.nz/act/public/2013/0068/latest/DLM1419043.html</a></td>
                    </tr>

                    <tr>
                        <td>Patents Regulations 2014</td>
                        <td><a target="_blank" rel="noreferrer" href="https://legislation.govt.nz/regulation/public/2014/0275/latest/DLM6166201.html">https://legislation.govt.nz/regulation/public/2014/0275/latest/DLM6166201.html</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Pharma Resources</td>
                    </tr>

                    <tr>
                        <td>Medsafe product search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.medsafe.govt.nz/regulatory/dbsearch.asp">https://www.medsafe.govt.nz/regulatory/dbsearch.asp</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default NewZealand