import React from 'react'

const SearchInput = () => {
    const filterResultsList = () => {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById('search');
        filter = input.value.toUpperCase();
        table = document.querySelector("table");
        tr = table.getElementsByTagName('tr');

        // Loop through all list items, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
        td= tr[i].getElementsByTagName("td")[0];
        txtValue = td.textContent || td.innerText;
        
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }
    
    return(
        <div class="relative text-gray-600 focus-within:text-gray-400">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                <button type="submit" class="p-1 focus:outline-none focus:shadow-outline">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </button>
            </span>
            
            <input type="text" id="search" className="my-0 _bg-gray-900 text-xl text-white rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" onKeyUp={filterResultsList} placeholder="Search for resource..." autocomplete="off" />
        </div>
    )
}

export default SearchInput